import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";

import "yet-another-react-lightbox/styles.css";
import "./index.css";

/* Slide Covers and Clips Covers */
import aboutMyFatherClip from "./img/ABOUTMYFATHERTRAILER.mov";
import aboutMyFatherPoster from "./img/ABOUTMYFATHER_STILL.png";
import fitsAndStartsClip from "./img/FITSANDSTARTSTRAILER.mp4";
import fitsAndStartsPoster from "./img/FITSANDSTARTS_STILL.png";
import goodGirlsGetHighClip from "./img/GOODGIRLS_STILL.jpg";
import goodGirlsGetHighPoster from "./img/GOODGIRLS_STILL.jpg";
import helloMyNameIsDorisClip from "./img/HELLOMYNAMEISDORISTRAILER.mp4";
import helloMyNameIsDorisPoster from "./img/HELLOMYNAMEISDORIS_STILL.jpg";
import workItClip from "./img/WORKITTRAILER.mov";
import workItPoster from "./img/WORKITTRAILER.jpg";

/* Posters */
import aboutMyFather from "./img/about_my_father.jpg";
import workIt from "./img/work_it.jpg";
import goodGirlsGetHigh from "./img/good_girls_get_high.jpg";
import helloMyNameIsDoris from "./img/hello_my_name_is_doris.jpg";
import fitsAndStarts from "./img/fits_and_starts.jpg";
import dickenson from "./img/dickenson.jpg";

const data = [
  {
    index: 0,
    name: "aboutMyFather",
    title: "About My Father",
    poster: aboutMyFatherPoster,
    fullSizeImage: aboutMyFatherPoster,
    sources: [
      {
        src: aboutMyFatherClip,
        type: "video/mp4",
      },
    ],
    type: "video",
  },
  {
    index: 4,
    name: "fitsAndStarts",
    title: "Fits and Starts",
    poster: fitsAndStartsPoster,
    sources: [
      {
        src: fitsAndStartsClip,
        type: "video/mp4",
      },
    ],
    type: "video",
  },
  {
    index: 2,
    name: "goodGirlsGetHigh",
    title: "Good Girls Get High",
    poster: goodGirlsGetHighPoster,
    sources: [
      {
        src: goodGirlsGetHighClip,
        type: "video/mp4",
      },
    ],
    type: "video",
  },
  {
    index: 3,
    name: "helloMyNameIsDoris",
    title: "Hello, My Name is Doris",
    poster: helloMyNameIsDorisPoster,
    sources: [
      {
        src: helloMyNameIsDorisClip,
        type: "video/mp4",
      },
    ],
    type: "video",
  },
  {
    index: 1,
    name: "workIt",
    title: "Work It",
    poster: workItPoster,
    sources: [
      {
        src: workItClip,
        type: "video/mp4",
      },
    ],
    type: "video",
  },
];

gsap.registerPlugin(ScrollTrigger);

const Filmography = () => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Add any GSAP animations or ScrollTrigger setup here if needed
  }, []);

  return (
    <section id="trailers" className="section-styles-table">
      <h1 className="filmography-header">Trailers</h1>
      <div className="section-row">
        <div className="section-col">
          <button
            className="trailer-card__wrapper"
            onClick={() => {
              setIndex(0);
              setOpen(true);
            }}
          >
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="2">
                <div
                  className="column__item-img"
                  style={{
                    backgroundColor: "black",
                    backgroundImage: `url(${aboutMyFather})`,
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <p className="caption-role">Director</p>
                <p className="caption-meta">Lionsgate / 2023</p>
              </figcaption>
            </figure>
          </button>
        </div>
        <div className="section-col">
          <button
            className="trailer-card__wrapper"
            onClick={() => {
              setIndex(4);
              setOpen(true);
            }}
          >
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="2">
                <div
                  className="column__item-img"
                  style={{
                    backgroundColor: "black",
                    backgroundImage: `url(${workIt})`,
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <p className="caption-role">Director</p>
                <p className="caption-meta">Netflix / 2020</p>
              </figcaption>
            </figure>
          </button>
        </div>
        <div className="section-col">
          <button
            className="trailer-card__wrapper"
            onClick={() => {
              setIndex(2);
              setOpen(true);
            }}
          >
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="2">
                <div
                  className="column__item-img"
                  style={{
                    backgroundColor: "black",
                    backgroundImage: `url(${goodGirlsGetHigh})`,
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <p className="caption-role">Writer / Director</p>
                <p className="caption-meta">Warner Blue Ribbon / 2019</p>
              </figcaption>
            </figure>
          </button>
        </div>
        <div className="section-col">
          <button
            className="trailer-card__wrapper"
            onClick={() => {
              setIndex(3);
              setOpen(true);
            }}
          >
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="2">
                <div
                  className="column__item-img"
                  style={{
                    backgroundColor: "black",
                    backgroundImage: `url(${helloMyNameIsDoris})`,
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <p className="caption-role">Writer / Co-Producer</p>
                <p className="caption-meta">Roadside Attractions / 2016</p>
              </figcaption>
            </figure>
          </button>
        </div>
        <div className="section-col">
          <button
            className="trailer-card__wrapper"
            onClick={() => {
              setIndex(1);
              setOpen(true);
            }}
          >
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="2">
                <div
                  className="column__item-img"
                  style={{
                    backgroundColor: "black",
                    backgroundImage: `url(${fitsAndStarts})`,
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <p className="caption-role">Writer / Director / Producer</p>
                <p className="caption-meta">The Orchard / 2017</p>
              </figcaption>
            </figure>
          </button>
        </div>
        <div className="section-col">
          <button
            className="trailer-card__wrapper disabled"
            disabled
            onClick={() => {
              setIndex(5);
              setOpen(true);
            }}
          >
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="2">
                <div
                  className="column__item-img"
                  style={{
                    backgroundColor: "black",
                    backgroundImage: `url(${dickenson})`,
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <p className="caption-role">Director</p>
                <p className="caption-meta">Apple / 2021</p>
              </figcaption>
            </figure>
          </button>
        </div>
      </div>
      <Lightbox
        close={() => setOpen(false)}
        index={index}
        open={open}
        plugins={[Video]}
        slides={data}
      />
    </section>
  );
};

export default Filmography;