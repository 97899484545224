import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

import "./index.css";

gsap.registerPlugin(ScrollTrigger);

const Bio = () => {
  const aboutRef = useRef(null);

  useEffect(() => {
    gsap.to(".bio-image-column", {
      opacity: 0.1,
      duration: 1.5,
      borderTopLeftRadius: 0,
      ease: "power3.out",
      scrollTrigger: {
        trigger: aboutRef.current,
        start: "top 40%",
        end: "bottom 20%",
        toggleActions: "play reverse play reverse",
        once: true,
      },
    });
  }, []);

  return (
    <section ref={aboutRef} id="about" className="bio-container">
      <div className="bio-column bio-image-column"></div>
      <div className="bio-column bio-text-column">
        <div className="bio-text-column-inner">
          <p className="bio-text">
            She most recently directed
            <span className="bio-text-heightened"> ABOUT MY FATHER</span>{" "}
              for Lionsgate, starring Robert De Niro, Kim Cattrall and Sebastian
              Maniscalco with Depth of Field producing.
          </p>
          <p className="bio-text">
            
              Only three other women have ever directed De Niro in a live-action
              film over his 60-year career: Nancy Meyers, Penny Marshall and
              Agnès Varda.
            
          </p>
          <p className="bio-text">
            
              Before that, Laura directed the Netflix film
            <span className="bio-text-heightened"> WORK IT </span>
            
              starring Sabrina Carpenter, Jordan Fisher and Liza Koshy. The film
              premiered worldwide as the #1 movie on Netflix and was well
              received by critics and audiences alike.
          </p>
          <p className="bio-text">
            
              Prior to that, Laura co-wrote and directed the feature
            <span className="bio-text-heightened"> GOOD GIRLS GET HIGH</span>,
            
              &nbsp;produced by Alloy Entertainment for Warner Blue Ribbon. It
              premiered to a sold-out crowd at the 2018 Los Angeles Film
              Festival and won the Audience Award at the Santa Fe Film Festival.
              The film was lauded by critics and premiered on HBO Max.
          </p>
          <p className="bio-text">
            
              She is the co-writer and co-producer of the film
            <span className="bio-text-heightened">
              {" "}
              HELLO, MY NAME IS DORIS
            </span>
            
              , directed by Michael Showalter and starring Sally Field. The
              feature is based on her short film
              <span className="bio-text-heightened"> DORIS & THE INTERN</span>.
              Released by Roadside Attractions, the film was one of the
              highest-grossing indies of 2016.
          </p>
          <p className="bio-text">
            
              Her feature debut as writer/director/producer
            <span className="bio-text-heightened"> FITS AND STARTS</span>{" "}
            
              starred Wyatt Cenac and Greta Lee and premiered at the 2017 SXSW
              Film Festival.
          </p>
          <p className="bio-text">
              She studied filmmaking in the graduate film program at NYU and
              worked as a cinematographer and producer prior to her career as a writer and
              director.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Bio;
