import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap');

  :root {
    --color-dark: #1C1C1C;
    --color-extra-dark: #0A0A0A;
    --color-light-gray: #DADDD8;
    --color-light-beige: #ECEBE4;
    --color-lighter-gray: #EEF0F2;
    --color-lightest-gray: #FAFAFF;
    --color-text-tan: #ff7900;
    --color-text-brown: rgb(113, 89, 66);
    --color-text-highlight-brown: rgb(232, 197, 158);

    --color-background: #000;
    --color-text: #BA481F;
    --color-primary: oklch(74% 0.15 32);
    --color-secondary: oklch(65% 0.13 210);
    --color-ui: oklch(65% 0.05 210);

    --color-bg: #b7b19f;
    --color-link: rgb(113, 89, 66);
    --color-link-hover: #BA481F;
    --color-heading: #111111;

    --ratio: 0.75;
    --grid-item-height: 32vw;
    --grid-item-width: calc(var(--grid-item-height) * var(--ratio));
    --thumb-height: 5vw;
    --thumb-width: calc(var(--thumb-height) * var(--ratio));

    --font-size: clamp(1rem, 2vw, 2rem);
    --font-size-h1: clamp(1rem, 1.7vw, 1.7rem);
    --font-size-h2: clamp(1rem, 2vw, 1.5rem);
    --font-size-h3: clamp(1rem, 3vw, 2.4rem);
    --font-size-h4: clamp(1.25rem, 2.5vw, 2rem);
    --font-size-h5: clamp(1rem, 2vw, 1.6rem);
    --font-size-h6: clamp(0.875rem, 1.75vw, 1.2rem);
    --font-size-tiny-p: clamp(0.875rem, 1.75vw, 1.2rem);
  }
  

  html {
    scroll-behavior: smooth;
    font-size: calc(1vw + 1vh + 0.5vmin);
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: var(--color-dark);
    color: var(--color-dark);
    font-family: 'Inter', sans-serif;
    font-size: var(--font-size);
    font-weight: 500;
    text-align:left;
    width: 100vw;
  }

  h1, h2, h3 {
    font-family: 'Inter', sans-serif;
    color: var(--color-text-black);
  }

  h1 { font-size: var(--font-size-h1); font-weight: 400; }
  h2 { font-size: var(--font-size-h2); }
  h3 {
    font-size: var(--font-size-h3);
    font-weight: 400;
    letter-spacing: -0.4px;
  }

  h4, h5, h6 {
    font-family: 'Cardo', serif;
  }

  h4 { font-size: var(--font-size-h4); }
  h5 { font-size: var(--font-size-h5); }
  h6 { font-size: var(--font-size-h6); }

  sub {
    font-family: 'Inter', sans-serif;
    font-size: clamp(0.8rem, 1vw, 0.75rem);
    color: var(--color-text-tan);
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.15;
    letter-spacing: 1px;
    margin: 0 0 30px 0;
  }

  p {
    font-family: 'Inter', sans-serif;
    font-size: clamp(0.8rem, 1.2vw, 0.8125rem);
    line-height: 1.5;
  }

  a {
    color: white;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-size: clamp(0.65rem, 1.2vw, 0.8125rem);
  }

  ul, ol {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
  }

  .text-center { text-align: center; }
  .mt-1 { margin-top: 1rem; }
  .mt-2 { margin-top: 2rem; }
  .mt-3 { margin-top: 3rem; }
  .mb-1 { margin-bottom: 1rem; }
  .mb-2 { margin-bottom: 2rem; }
  .mb-3 { margin-bottom: 3rem; }
`;

export default GlobalStyles;
