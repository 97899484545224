import React from "react";
import ReactPlayer from "react-player";
import "./index.css";
import clip from "./img/deniro.mp4";

const Deniro = () => {
  return (
    <section id="press" className="section-styles-deniro">
      <h2 className="press-header-deniro">
        Robert De Niro Talks about Laura on the Today Show
      </h2>
      <div className="video-container">
        <ReactPlayer
          controls
          fallback
          playIcon
          playing
          className="react-player"
          url={clip}
          width="100%"
          height="100%"
        />
      </div>
    </section>
  );
};

export default Deniro;
