import "./index.css";
import React, { useLayoutEffect, useRef, useCallback } from "react";

const Footer = () => {
  const footerRef = useRef();

  return (
    <section
      ref={footerRef}
      id="contact"
      className="section-styles-footer"
      data-dark-header
    >
      <div className="title-styles">
        <p className="contact-styles">Representation: Chris Licata</p>
        <p className="contact-styles">
          9460 Wilshire Blvd, 5th Floor Beverly Hills, CA 90212
        </p>
        <p className="contact-styles">chrislicata@42mp.com</p>
        <p className="contact-styles">213.354.9700</p>
      </div>
    </section>
  );
};

export default Footer;
