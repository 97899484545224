import { useEffect, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./index.css";

gsap.registerPlugin(ScrollTrigger);

const Nav = ({ fontColor }) => {
  const [activeSection, setActiveSection] = useState(0);

  const getLinkColor = (sectionIndex) => {
    return activeSection === sectionIndex
      ? "var(--color-text-tan)"
      : "var(--color-black)";
  };

  const getLinkDecoratrion = (sectionIndex) => {
    return activeSection === sectionIndex ? "underline" : "none";
  };

  useEffect(() => {
    const sections = ["about", "trailers", "press", "contact"];
    const sectionElements = sections.map((section) =>
      document.getElementById(section)
    );

    const updateActiveSection = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Check if we're at the bottom of the page
      if (scrollPosition + windowHeight >= documentHeight - 50) {
        // 50px threshold
        setActiveSection(4); // Contact section
        return;
      }

      // Check other sections
      for (let i = 0; i < sectionElements.length; i++) {
        const section = sectionElements[i];
        if (
          section &&
          scrollPosition <
            section.offsetTop + section.offsetHeight - windowHeight / 2
        ) {
          setActiveSection(i + 1);
          return;
        }
      }
    };

    window.addEventListener("scroll", updateActiveSection);
    window.addEventListener("resize", updateActiveSection);
    updateActiveSection(); // Initial call to set the active section on mount

    return () => {
      window.removeEventListener("scroll", updateActiveSection);
      window.removeEventListener("resize", updateActiveSection);
    };
  }, []);
  //ref={navRef}
  return (
    <nav className="nav-styles" style={{ borderColor: fontColor }}>
      <div className="nav-inner-styles">
        <div className="title-left-styles">
          <h1 className="title-left-styles-heading">Laura Terruso</h1>
        </div>
        <ul className="ul-list-right-styles">
          <li className="ul-list-right-item-styles">
            <a
              style={{
                color: getLinkColor(1),
                textDecoration: getLinkDecoratrion(1),
              }}
              href="#about"
            >
              About
            </a>
          </li>
          <li className="ul-list-right-item-styles">
            <a
              style={{
                color: getLinkColor(2),
                textDecoration: getLinkDecoratrion(2),
              }}
              href="#trailers"
            >
              Trailers
            </a>
          </li>
          <li className="ul-list-right-item-styles">
            <a
              style={{
                color: getLinkColor(3),
                textDecoration: getLinkDecoratrion(3),
              }}
              href="#press"
            >
              Press
            </a>
          </li>
          <li className="ul-list-right-item-styles">
            <a
              style={{
                color: getLinkColor(4),
                textDecoration: getLinkDecoratrion(4),
              }}
              href="#contact"
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
