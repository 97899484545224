import React from "react";
import "./index.css";

const Intro = () => {
  return (
    <div className="section-styles-intro">
      <div className="column left-column">
        <div className="intro-text-wrapper-styles">
          <p className="intro-text-item-styles">
            Laura Terruso is an award-winning director, screenwriter, and
            producer who specializes in the field of comedy, embracing a
            heartfelt and character-driven approach. Only eight years out of
            film school, Laura has already directed four feature films and an
            episode of Apple TV's DICKINSON. Her films range from independently
            financed features to multi-million-dollar studio fare.
          </p>
        </div>
      </div>
      <div className="column right-column">
        <div className="image-wrapper">
          <img
            alt="Laura Terruso Director Screenwriter Producer"
            className="scroll-image-4"
            src={require("./img/12.jpg")}
          />
        </div>
      </div>
    </div>
  );
};

export default Intro;