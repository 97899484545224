import React from "react";
import './index.css';

const Press = () => {
    const articles = [
      {
        publication: "Variety",
        link: "https://variety.com/2023/film/reviews/about-my-father-review-sebastian-maniscalco-robert-de-niro-1235619482/",
        title:
          "Whether or not Maniscalco has a legitimate future as a movie star, he proves a likeable presence as a romantic lead, while director Laura Terruso skillfully delivers comedic payoffs that tap into his wheelhouse while introducing him to a wider audience. ",
      },
      {
        publication: "Collider",
        link: "https://collider.com/about-my-father-review/",
        title: `A throwback of a comedy with plenty of heart, "About My Father" is one of the most pleasant surprises of the year thus far. There is something so sweet and honest about the film as it's a romantic comedy where the love story is about the bond between father and son.`,
      },
      {
        publication: "Indiewire",
        link: "https://www.indiewire.com/criticism/movies/about-my-father-review-robert-de-niro-comedy-1234867306/",
        title: `Director Laura Terruso, who co-wrote the Sally Field dark comedy “Hello, My Name Is Doris,” uses her sharp comedic timing to smooth what could easily have become unwieldy group scenes.`,
      },
      {
        publication: "The Guardian",
        link: "https://www.theguardian.com/film/2020/aug/07/work-it-review-netflix-comedy",
        title: `There's an easy sleepover movie charm to the Netflix comedy WORK IT, a charm that's proved harder to find in their many other teen offerings of late.`,
      },
      {
        publication: "Them",
        link: "https://www.them.us/story/lgbtq-streaming-guide-august-2020",
        title: `Work It is a zippy teenage romp about chosen family.`,
      },
      {
        publication: "Variety",
        link: "https://variety.com/2018/film/festivals/good-girls-get-high-review-1202954065/",
        title: `Full of charm, humor and heart, director Laura Terruso's, GOOD GIRLS GET HIGH, mixes it up, delivering a "high" concept comedy.`,
      },
      {
        publication: "The Hollywood Reporter",
        link: "https://www.hollywoodreporter.com/review/good-girls-get-high-1253185",
        title: `There's also a welcome emotional warmth on display, from the deep friendship between the two female protagonists to their loving interactions with their parents to their random encounters with a pregnant woman(Lauren Lapkus, always a welcome presence) who turns out to be the coolest, most laid- back cop ever.`,
      },
      {
        publication: "The Moveable Feast",
        link: "http://moveablefest.com/laura-terruso-fits-and-starts/",
        title: `FITS & STARTS is exceedingly sharp and funny. Terruso brillantly engineers something even greater than the sum of the film's parts.`,
      },
      {
        publication: "The Austin Chronicle",
        link: "https://www.austinchronicle.com/daily/sxsw/2017-03-11/sxsw-film-review-fits-and-starts/",
        title: `The satire is turned up to high, as a talented but neurotic writer (Wyatt Cenac) tries to find himself and escape the shadow of his successful writer wife (Greta Lee) in the world premiere of Laura Terruso’s directorial debut, the dazzling and sweet comedy Fits and Starts.`,
      },
    ];

  return (
    <section id="press" className="section-styles-press">
      <h1 className="press-header">Press</h1>
      <div className="press-grid-container">
        {articles.map((article, index) => (
          <div key={index} className="press-grid-item">
            <a target="_blank" href={article.link}>
              <sub>{article.publication}</sub>
            </a>
            <p className="press-quote">{article.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Press;