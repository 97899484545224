import './App.css';
import React, { useEffect, useState } from 'react';
import GlobalStyles from './GlobalStyles';
import { useInView } from 'react-intersection-observer';

import Nav from './components/nav';
import Intro from './components/intro';
import Bio from './components/bio';
import Filmography from './components/filmography';
import Press from './components/press';
import Deniro from "./components/deniro";
import Footer from './components/footer';

function App() {
  const [fontColor, setFontColor] = useState("--color-dark"); // Default font color
  const [activeSection, setActiveSection] = useState(0); // Default font color

  const { ref: introRef, inView: firstDivInView } = useInView({
    threshold: 0.1, // Adjust threshold as needed
  });

  const { ref: bioRef, inView: secondDivInView } = useInView({
    threshold: 0.1,
  });

  const { ref: tableRef, inView: thirdDivInView } = useInView({
    threshold: 0.1,
  });

  const { ref: scenesRef, inView: fourthDivInView } = useInView({
    threshold: 0.1,
  });

  const { ref: pressRef, inView: fifthDivInView } = useInView({
    threshold: 0.1,
  });

  const { ref: footerRef, inView: sixthDivInView } = useInView({
    threshold: 0.1,
  });

  // Change font color based on which div is in view
  useEffect(() => {
    if (firstDivInView) {
      setFontColor("var(--color-lightest-gray)");
      setActiveSection(0);
    } else if (secondDivInView) {
      setFontColor("var(--color-dark)");
      setActiveSection(1);
    } else if (thirdDivInView) {
      setFontColor("var(--color-dark)");
      setActiveSection(2);
    } else if (fourthDivInView) {
      setFontColor("var(--color-lightest-gray)");
      setActiveSection(3);
    } else if (fifthDivInView) {
      setFontColor("var(--color-dark)");
      setActiveSection(4);
    } else if (sixthDivInView) {
      setFontColor("var(--color-lightest-gray)");
      setActiveSection(5);
    } else {
      setFontColor("var(--color-dark)"); // Default color
      setActiveSection(0);
    }
  }, [
    firstDivInView,
    secondDivInView,
    thirdDivInView,
    fourthDivInView,
    fifthDivInView,
    sixthDivInView,
  ]);

  return (
    <>
      <GlobalStyles />
      <div className="App">
        <div className="main">
          <Nav fontColor={fontColor} activeSection={activeSection} />
          <div ref={introRef}>
            <Intro />
          </div>
          <div ref={bioRef}>
            <Bio />
          </div>
          <div ref={tableRef}>
            <Filmography />
          </div>
          <div ref={pressRef}>
            <Press />
          </div>
          <div>
            <Deniro />
          </div>
          <div ref={footerRef}>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
